import React from "react";
import "./EmailMeButton.scss";

const EmailMeButton = ({ email, textColor, iconColor }) => {
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(email).then(
      () => alert("Email copied to clipboard!"),
      () => alert("Failed to copy email to clipboard.")
    );
  };

  return (
    <div className="email-me">
      <span
        className="email-me__text"
        onClick={handleCopyToClipboard}
        style={{ color: textColor || "black" }} // Set text color via textColor prop
      >
        Copy & Paste My Email
        <svg
          width="24"
          height="24"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.38896 4.16022C6.50272 4.16022 4.16016 6.50272 4.16016 9.38902V14.8927C4.16016 17.7789 6.50265 20.1215 9.38896 20.1215V18.4702C7.41145 18.4702 5.81136 16.8702 5.81136 14.8926V9.38896C5.81136 7.41145 7.41136 5.81136 9.38896 5.81136H14.8926C16.8701 5.81136 18.4702 7.41136 18.4702 9.38896H20.1215C20.1215 6.50272 17.779 4.16016 14.8927 4.16016L9.38896 4.16022ZM17.1016 11.8729C14.2154 11.8729 11.8728 14.2154 11.8728 17.1017V22.6053C11.8728 25.4916 14.2153 27.8341 17.1016 27.8341H22.6053C25.4915 27.8341 27.8341 25.4916 27.8341 22.6053V17.1017C27.8341 14.2154 25.4916 11.8729 22.6053 11.8729H17.1016ZM13.524 17.1017C13.524 15.1242 15.124 13.5241 17.1016 13.5241H22.6053C24.5828 13.5241 26.1829 15.1241 26.1829 17.1017V22.6053C26.1829 24.5828 24.5829 26.1829 22.6053 26.1829H17.1016C15.1241 26.1829 13.524 24.5829 13.524 22.6053V17.1017Z"
            fill={iconColor || "white"} // Set icon color via iconColor prop
          ></path>
        </svg>
      </span>
    </div>
  );
};

export default EmailMeButton;
