import React, { useState } from "react"; // Import useState for managing state
import "./Header.scss";
import logo from "../../assets/logos/lindsay-adams-media-and-design-brant-ontario-favicon.svg";
import { Link } from "react-router-dom";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for toggling menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Function to close the menu
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="header">
      <Link to="/" className="header__logo-link">
        <img className="header__logo" src={logo} alt="L.A. Media and Design icon" />
      </Link>

      <button
        className={`header__hamburger ${isMenuOpen ? "header__hamburger--open" : ""}`}
        onClick={toggleMenu}
        aria-label="Toggle navigation menu"
      >
        {isMenuOpen ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M19.6,5.6L18.4,4.4L12,10.8L5.6,4.4L4.4,5.6L10.8,12L4.4,18.4L5.6,19.6L12,13.2L18.4,19.6L19.6,18.4L13.2,12L19.6,5.6Z" />
          </svg> // Close icon
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
          </svg> // Hamburger icon
        )}
      </button>

      <div className={`header__links ${isMenuOpen ? "header__links--open" : ""}`}>
        <Link to="/services/logo-design" onClick={closeMenu}>Logo Design</Link>
        <Link to="/services/ad-design" onClick={closeMenu}>Ad Design</Link>
        <Link to="/services/digital-design" onClick={closeMenu}>Digital Design</Link>
        <Link to="/services/marketing-collateral" onClick={closeMenu}>Marketing Collateral</Link>
        <Link to="/portfolio" onClick={closeMenu}>Portfolio</Link>
       
        <a className="header__phone" href="tel:+12269209764" onClick={closeMenu}>
          226.920.9764
        </a>
        <a className="header__email" href="https://www.linkedin.com/comm/mynetwork/discovery-see-all?usecase=PEOPLE_FOLLOWS&followMember=lindsay-adams-design" onClick={closeMenu}>
        Follow me on LinkedIn
        </a>
        <a className="header__email" href="mailto:lindsayadams@gmail.com">lindsayadams@gmail.com</a>
      </div>
    </nav>
  );
}

