import "./Footer.scss";
import logo from "../../assets/logos/lindsay-adams-media-and-design-brant-ontario-logo.svg";
import linkedInlogo from "../../assets/logos/LI-In-Bug-black.png";
import dribbblelogo from "../../assets/logos/dribbble-icon.svg";
import githublogo from "../../assets/logos/github-mark-black.svg";
import ContactMeButton from "../../components/ContactMeButton/ContactMeButton";
import EmailMeButton from "../../components/EmailMeButton/EmailMeButton";
import ServiceButton from "../../components/ServiceButton/ServiceButton";


export default function Footer() {
  return (
    <div className="footer">
      <div className="footer__column-1">
<img className="footer__logo" src={logo} alt="L.A. Media and Design Logo" />
<h5>My name is Lindsay, and I specialize in versatile graphic design solutions tailored to your needs. With expertise in print production and digital media, I deliver high-quality results you can rely on—on time, every time.</h5>
   {/* Button Container */}
   <div className="footer__button-container">
          <ContactMeButton 
            label="Contact me"
            href="/contact"
            variant="default" 
          />
         <EmailMeButton
      email="lindsayadamsdesign@gmail.com"
      textColor="#1F2022"
      iconColor="#1F2022"
      />
        </div>
        <h5>L.A. Media and Design</h5>
        <h5>Brant, Ontario</h5>
        <h5> <a className="footer__phone" href="tel:+12269209764">
          226.920.9764
        </a></h5>
        <h5><a href="mailto:lindsayadams@gmail.com">lindsayadams@gmail.com</a></h5>
      </div>

      <div className="footer__column-2">
     <h2>Design You Can Rely On</h2> 
<h5>Partnering with marketing managers and business owners to deliver design and marketing solutions that drive results.</h5>
   {/* Button Container */}
   <div className="footer__button-container">
          <ServiceButton 
            label="Logo Design"
            href="/services/logo-design" // Link to the web design service page
            variant="default" 
          />
          <ServiceButton 
            label="Ad Design"
            href="/services/ad-design" // Link to the branding service page
            variant="default"
          />
          <ServiceButton 
            label="Digital Design"
            href="/services/digital-design" // Link to the marketing service page
            variant="default"
          />
          <ServiceButton 
            label="Marketing Collateral"
            href="/services/marketing-collateral" // Link to the consultation service page
            variant="default"
          />
        </div>
         {/* Social Media Icons */}
         <div className="footer__social-media">
          <a href="https://www.linkedin.com/in/lindsay-adams-design/" target="_blank" rel="noopener noreferrer">
            <img src={linkedInlogo} alt="LinkedIn" className="footer__social-icon" />
          </a>
          <a href="https://dribbble.com/lindsayadamsdesign" target="_blank" rel="noopener noreferrer">
            <img src={dribbblelogo} alt="Dribbble" className="footer__social-icon" />
          </a>
          <a href="https://github.com/adams-infinity" target="_blank" rel="noopener noreferrer">
    <img src={githublogo} alt="GitHub" className="footer__social-icon" />
  </a>
        </div>
      </div>
      </div>
  );
}